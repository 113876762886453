import * as React from "react";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
export default function StickyHeadTable({ data }) {
  let totalSingleCount = 0;
  function singlecounter(datatocount) {
    let sum = 0;
    if (datatocount) {
      Object.keys(datatocount).map(
        (item, i) => ((sum += 1), (totalSingleCount += 1))
      );
    }
    return sum;
  }
  function bulkcounter(datatocount) {
    let sum = 0;
    if (datatocount) {
      Object.keys(datatocount).map((item, i) =>
        Object.keys(datatocount[item]).map((x, is) =>
          datatocount[item][x].map(
            (xx) => ((sum += 1), (totalSingleCount += 1))
          )
        )
      );
    }
    return sum;
  }
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: "80vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align={"center"}>Name</TableCell>
              <TableCell align={"center"}>Surname</TableCell>
              <TableCell align={"center"}>Org Name</TableCell>
              <TableCell align={"center"}>Signed Mou ?</TableCell>
              <TableCell align={"center"}>Single Data Upload</TableCell>
              <TableCell align={"center"}>Single Data Count</TableCell>
              <TableCell align={"center"}>Multy Data Upload</TableCell>
              <TableCell align={"center"}>Multy Data Count</TableCell>
              <TableCell align={"center"}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, i) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  <TableCell align={"center"}>{row[0].Name}</TableCell>
                  <TableCell align={"center"}>{row[0].Surname}</TableCell>
                  <TableCell align={"center"}>{row[0].Org_Name}</TableCell>
                  <TableCell align={"center"}>
                    {row[0].mou ? "yes" : "no"}
                  </TableCell>
                  <TableCell align={"center"}>
                    {row[0].Singledata ? "yes" : "no"}
                  </TableCell>
                  <TableCell align={"center"}>
                    {singlecounter(row[0].Singledata)}
                  </TableCell>
                  <TableCell align={"center"}>
                    {row[0].Bulkdata ? "yes" : "no"}
                  </TableCell>
                  <TableCell align={"center"}>
                    {bulkcounter(row[0].Bulkdata)}
                  </TableCell>
                  <TableCell align={"center"}>
                    <Button>
                      <Link
                        to={`/orgdata/${row[1]}`}
                        style={{ textDecoration: "none" }}
                      >
                        Show More
                      </Link>
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow
              sx={{
                background: "#c9af7f",
                background:
                  "linear-gradient(90deg, rgba(205,65,183,1) 0%, rgba(205,65,101,1) 33%, rgba(229,116,79,1) 66%, rgba(255,226,111,1) 100%)",
              }}
            >
              <TableCell colSpan={7} />
              <TableCell>Stubmitted Data Subtotal</TableCell>
              <TableCell>{totalSingleCount}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
