// Basic
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// Components
//External func components
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { getDatabase, ref, update } from "firebase/database";
import { useForm } from "react-hook-form";
// MUI
import { Typography, Button, Container, TextField, Box } from "@mui/material";

export default function Art({ oldData, id }) {
  const uid = localStorage.getItem("uid");
  const db = getDatabase();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Name: oldData.Name,
    },
  });
  const onSubmit = (data) => {
    update(ref(db, "users/" + uid + `/${id}`), {
      Name: data.name ? data.name : oldData.Name,
      Surname: data.surname ? data.surname : oldData.Surname,
      Email: data.Email ? data.Email : oldData.Email,
      Phone: data.PhoneNumber ? data.PhoneNumber : oldData.Phone,
      Org_Description: data.organizationDescription
        ? data.organizationDescription
        : oldData.Org_Description,
      Org_Name: data.organizationName
        ? data.organizationName
        : oldData.Org_Name,
      Org_Website: data.organizationWebsite
        ? data.organizationWebsite
        : oldData.Org_Website,
    })
      .then(() => {
        enqueueSnackbar("Informations successfully updated", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
            autoHideDuration: 6000,
          },
        });
      })
      .catch((err) => {
        enqueueSnackbar("Error, Please try again", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
            autoHideDuration: 6000,
          },
        });
      });
  };

  return (
    <Container maxWidth="lg">
      <SnackbarProvider maxSnack={4} />

      <Typography
        href="/"
        sx={{
          mt: 10,
          mb: 5,
          fontSize: "30px",
          textAlign: "left",
          fontFamily: "Inter",
          fontWeight: "600",
          color: "rgb(55, 59, 89)",
        }}
      >
        Personal Informations
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            "& > :not(style)": {
              m: 1,
              width: { xs: "100%", lg: "48%" },
            },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label={oldData.Name ? oldData.Name : "Name"}
            {...register("name", { required: oldData.Name ? false : true })}
            error={errors.name}
          ></TextField>

          <TextField
            label={oldData.Surname ? oldData.Surname : "Surname"}
            {...register("surname", {
              required: oldData.Surname ? false : true,
            })}
            error={errors.surname}
          ></TextField>
          <TextField
            label={oldData.Email ? oldData.Email : "Email"}
            {...register("Email", { required: oldData.Email ? false : true })}
            error={errors.Email}
          ></TextField>
          <TextField
            label={oldData.Phone ? oldData.Phone : "Phone Number"}
            {...register("PhoneNumber", {
              required: oldData.Phone ? false : true,
            })}
            error={errors.PhoneNumber}
          ></TextField>
        </Box>
        <Typography
          variant="h5"
          href="/"
          sx={{
            mt: 5,
            mb: 5,
            fontSize: "30px",
            textAlign: "left",
            fontFamily: "Inter",
            fontWeight: "600",
            color: "rgb(55, 59, 89)",
          }}
        >
          Organization Informations
        </Typography>
        <Box
          sx={{
            "& > :not(style)": { m: 1, width: { xs: "100%", lg: "48%" } },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label={oldData.Org_Name ? oldData.Org_Name : "Organization Name"}
            {...register("organizationName", {
              required: oldData.Org_Name ? false : true,
            })}
            error={errors.organizationName}
          ></TextField>
          <TextField
            label={
              oldData.Org_Website ? oldData.Org_Website : "Organization Website"
            }
            {...register("organizationWebsite", {
              required: oldData.Org_Website ? false : true,
            })}
            error={errors.organizationWebsite}
          ></TextField>
        </Box>
        <Box
          sx={{
            "& > :not(style)": {
              m: 1,
              width: { xs: "100%", lg: "97.5%" },
            },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            placeholder={
              oldData.Org_Description
                ? oldData.Org_Description
                : "Organization Description"
            }
            fullWidth
            multiline
            rows={4}
            {...register("organizationDescription", {
              required: oldData.Org_Description ? false : true,
            })}
            error={errors.organizationDescription}
          ></TextField>
        </Box>

        <Button
          type="submit"
          variant="contained"
          sx={{ m: 1, width: "150px", height: "50px" }}
        >
          Save
        </Button>
      </form>
    </Container>
  );
}
