import React from "react";
import { useState, useEffect } from "react";
import { getAuth, updatePassword, getAdditionalUserInfo } from "firebase/auth";
import { app } from "../../Services/Firebase/firebase-config";
import { useDispatch } from "react-redux";
import { update } from "../../Redux/accountSlice";
import Logo from "../../Assets/SVG/index";
import { useNavigate } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { getDatabase, ref, set, onValue, push } from "firebase/database";

import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Alert,
  AlertTitle,
  Stack,
} from "@mui/material";
export default function Login() {
  const db = getDatabase();
  const navigate = useNavigate();
  const [display, setDisplay] = useState("none");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const authentication = getAuth();
  const uid = localStorage.getItem("uid");
  const handleAction = () => {
    if (
      password === confirmPassword &&
      password !== "" &&
      password.length >= 6
    ) {
      updatePassword(authentication.currentUser, password)
        .then(() => {
          localStorage.setItem("isNewUser", false);
          //set empty profile
          set(ref(db, "users/" + uid), {
            auth: true,
          })
            .then(() => {})
            .catch((err) => {});

          navigate("/home");
        })
        .catch((error) => {
          if (
            error.message === "Firebase: Error (auth/requires-recent-login)."
          ) {
            localStorage.removeItem("Auth Token");
            localStorage.removeItem("ID Token");
            localStorage.removeItem("uid");
            localStorage.removeItem("isNewUser");

            navigate("/login");
          } else {
            enqueueSnackbar("Error, Please try again!", {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
                autoHideDuration: 6000,
              },
            });
          }
        });
    } else {
      if (password !== confirmPassword) {
        enqueueSnackbar("Password don't match, Please try again!", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
            autoHideDuration: 6000,
          },
        });
      } else if (password.length < 6) {
        enqueueSnackbar("Password should be at least 6 characters!", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
            autoHideDuration: 6000,
          },
        });
      } else {
        enqueueSnackbar("Error, Please try again!", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
            autoHideDuration: 6000,
          },
        });
      }
    }
  };
  useEffect(() => {
    let authToken = localStorage.getItem("Auth Token");
    if (!authToken) {
      navigate("/login");
    }
  }, []);
  return (
    <Box>
      <SnackbarProvider maxSnack={3} />
      <Box
        sx={{
          minWidth: "100vw",
          width: "100vw",
          height: "100vh",

          color: "#050A30",
          background: "white",
        }}
      >
        <Box
          sx={{
            width: "380px",
            margin: "0 auto",
            paddingTop: {
              xs: "3% ",
              md: "20%",
            },
          }}
        >
          <Stack sx={{ width: "100%", display: { display } }} spacing={2}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              <strong>Error</strong> - Password not matching.
            </Alert>
          </Stack>
          <Typography sx={{ typography: { xs: "h4" }, fontWeight: "bold" }}>
            Set New Password
          </Typography>

          <Box sx={{ marginTop: "16px" }}>
            <FormControl
              sx={{ marginTop: "32px", width: "100%" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <Logo name="hide" width="14px" height="14px" />
                      ) : (
                        <Logo name="show" width="14px" height="14px" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>

            <FormControl
              sx={{ marginTop: "32px", width: "100%" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Confirm New Password
              </InputLabel>
              <OutlinedInput
                id="Confirm New Password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => setConfirmPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <Logo name="hide" width="14px" height="14px" />
                      ) : (
                        <Logo name="show" width="14px" height="14px" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm New Password"
              />
            </FormControl>
            <Button
              variant="contained"
              onClick={handleAction}
              sx={{
                width: "282px",
                height: "48px",
                marginTop: "32px",

                color: "white",
                background: "linear-gradient(to bottom, #373B59, #050A30)",
                borderRadius: "16px",
              }}
            >
              Set New Password
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
