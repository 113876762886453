// Basic
import * as React from "react";
import { useState } from "react";
// Components
import Icon from "../../Assets/SVG/index";
//External func components
import { Dropbox } from "dropbox";
import { getDatabase, remove, ref } from "firebase/database";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
// MUI
import Typography from "@mui/material/Typography";

import {
  Stack,
  CardHeader,
  Card,
  CardContent,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
export default function Prev({ data, id }) {
  const uid = localStorage.getItem("uid");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const db = getDatabase();

  function deleteFile(name, fileid) {
    const dbx = new Dropbox({
      clientId: process.env.REACT_APP_DROPBOX_CLIENT_KEY,
      clientSecret: process.env.REACT_APP_DROPBOX_CLIENT_SECRET_KEY,
      refreshToken: process.env.REACT_APP_DROPBOX_REFRESH_TOKEN_KEY,
    });
    dbx.filesDeleteV2({ path: `/${id}/` + name }).then((response) => {});
    remove(ref(db, "users/" + uid + `/${id}` + `/uploads` + `/${fileid}`))
      .then((resp) => {
        enqueueSnackbar("Your file has been deleted successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
            autoHideDuration: 6000,
          },
        });
      })
      .catch((eer) => {
        enqueueSnackbar("Error, Please try again", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
            autoHideDuration: 6000,
          },
        });
      });
  }

  if (data)
    return (
      <Box>
        <SnackbarProvider maxSnack={4} />
        <Typography
          variant="h5"
          sx={{
            mt: 10,
            mb: 5,
            fontSize: "24px",
            textAlign: "left",
            fontFamily: "Inter",
            fontWeight: "600",
            color: "rgb(55, 59, 89)",
          }}
        >
          {data.Org_Name ? `Org Name: ${data.Org_Name}` : ""}
        </Typography>

        <Box maxWidth="full" sx={{ pt: 2 }}>
          <Typography
            variant="h5"
            href="/"
            sx={{
              mb: 5,
              fontSize: "24px",
              textAlign: "left",
              fontFamily: "Inter",
              fontWeight: "600",
              color: "rgb(55, 59, 89)",
            }}
          >
            My Files:
          </Typography>

          {data.uploads ? (
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction="row"
              useFlexGap
              flexWrap="wrap"
            >
              {Object.values(data.uploads).map((keyName, i) => (
                <div key={i}>
                  <Card
                    sx={{
                      width: 270,
                      border: "solid",
                      borderColor: "#ccc",
                      borderWidth: "1px",
                      boxShadow: "rgba(5, 10, 48, 0.05) 0px 3px 5px",
                      borderRadius: "10px",
                    }}
                  >
                    <CardHeader
                      action={
                        <IconButton aria-label="settings" onClick={handleClick}>
                          <MoreVertIcon />
                        </IconButton>
                      }
                      title={
                        keyName["FileName"].split(".")[0].length > 12
                          ? `${keyName["FileName"]
                              .split(".")[0]
                              .substring(0, 15)}...`
                          : keyName["FileName"].split(".")[0]
                      }
                      subheader={`${keyName["FileDate"].slice(
                        0,
                        10
                      )} at ${keyName["FileDate"].slice(11, 16)}`}
                    />
                    <Icon
                      name="file"
                      width={"100px"}
                      extension={keyName["FileName"]
                        .toUpperCase()
                        .split(".")
                        .slice(-1)}
                    />
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        {keyName["FileSize"] / 1000} MB
                      </Typography>
                    </CardContent>
                  </Card>
                  <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                    <MenuItem
                      onClick={(e) =>
                        deleteFile(keyName["FileName"], keyName["FileId"])
                      }
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </div>
              ))}
            </Stack>
          ) : null}
        </Box>
      </Box>
    );
}
