import React from "react";

const SVG = ({ w, h, c }) => (
  <svg x="0px" y="0px" width={w} height={h} viewBox="0 0 512.021 512.021">
    <path
      d="M71.294,335.13c8.333,8.33,21.84,8.328,30.17-0.005c8.33-8.333,8.328-21.84-0.005-30.17l-48.953-48.936l74.001-74.001
                    c44.668-44.668,108.079-62.868,168.762-50.082c11.529,2.429,22.844-4.948,25.273-16.477s-4.948-22.844-16.477-25.273
                    c-74.65-15.728-152.755,6.688-207.729,61.662L7.248,240.936c-8.332,8.332-8.331,21.842,0.003,30.172L71.294,335.13z"
    />
    <path
      d="M506.77,240.913l-64.043-64.021c-8.333-8.33-21.84-8.328-30.17,0.005c-8.33,8.333-8.328,21.84,0.005,30.17l48.953,48.936
                    l-74.001,74.001c-44.668,44.669-108.079,62.868-168.762,50.082c-11.529-2.429-22.844,4.948-25.273,16.477
                    c-2.429,11.529,4.948,22.844,16.477,25.273c74.65,15.728,152.755-6.688,207.729-61.662l89.088-89.088
                    C515.105,262.753,515.104,249.243,506.77,240.913z"
    />
    <path
      d="M150.344,256.011c0,11.782,9.551,21.333,21.333,21.333c11.782,0,21.333-9.551,21.333-21.333c0-35.343,28.657-64,64-64
                    c11.782,0,21.333-9.551,21.333-21.333c0-11.782-9.551-21.333-21.333-21.333C198.103,149.344,150.344,197.103,150.344,256.011z"
    />
    <path
      d="M321.011,256.011c0,35.343-28.657,64-64,64c-11.782,0-21.333,9.551-21.333,21.333c0,11.782,9.551,21.333,21.333,21.333
                    c58.907,0,106.667-47.759,106.667-106.667c0-11.782-9.551-21.333-21.333-21.333C330.562,234.677,321.011,244.229,321.011,256.011
                    z"
    />
    <path
      d="M506.762,6.259c-8.331-8.331-21.839-8.331-30.17,0L7.259,475.592c-8.331,8.331-8.331,21.839,0,30.17
                    c8.331,8.331,21.839,8.331,30.17,0L506.762,36.429C515.094,28.098,515.094,14.59,506.762,6.259z"
    />
  </svg>
);
export default SVG;
