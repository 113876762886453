import React from "react";
import { useState, useEffect } from "react";
import Logo from "../../Assets/SVG/index";
import { useNavigate } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import backgroundIMG from "../../Assets/Images/lunarc.jpeg";
import { Link } from "react-router-dom";

import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
export default function Signup({}) {
  const auth = getAuth();
  const navigate = useNavigate();
  const [display, setDisplay] = useState("none");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const register = (e) => {
    e.preventDefault();
    // setError("");

    // Create a new user with email and password using firebase
    createUserWithEmailAndPassword(auth, email, password)
      .then((res) => {
        console.log(res.user);
        navigate("/login");
      })
      .catch((err) => {
        console.log(err.message);
        enqueueSnackbar("Error, An Account with this emial already exists.", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          autoHideDuration: 18000,
        });
      });
  };

  return (
    <Box>
      <Box
        sx={{
          minWidth: {
            xs: "100vw", // theme.spacing(3)
            md: "30vw", // theme.spacing(5)
            lg: "50vw", // theme.spacing(5)
          },
          maxWidth: {
            xs: "100vw", // theme.spacing(3)
            md: "30vw", // theme.spacing(5)
            lg: "50vw", // theme.spacing(5)
          },
          minHeight: {
            xs: "30vh", // theme.spacing(3)
            md: "100vh", // theme.spacing(5)
          },
          float: {
            sm: "none",
            md: "left",
          },

          backgroundImage: `url(${backgroundIMG})`,
          backgroundColor: "black",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
        }}
      ></Box>

      <Box
        sx={{
          minWidth: {
            xs: "100vw", // theme.spacing(3)
            md: "70vw", // theme.spacing(5)
            lg: "50vw", // theme.spacing(5)
          },
          maxWidth: {
            xs: "100vw", // theme.spacing(3)
            md: "70vw", // theme.spacing(5)
            lg: "50vw", // theme.spacing(5)
          },
          minHeight: {
            xs: "70vh", // theme.spacing(3)
            md: "100vh", // theme.spacing(5)
          },
          float: {
            sm: "none",
            md: "left",
          },
          color: "#050A30",
          background: "white",
        }}
      >
        <Box
          sx={{
            width: "380px",
            margin: "0 auto",
            paddingTop: {
              xs: "3% ",
              md: "20%",
            },
          }}
        >
          <SnackbarProvider maxSnack={3} />

          <Typography sx={{ typography: { xs: "h4" }, fontWeight: "bold" }}>
            Sign Up
          </Typography>

          <Typography variant="body1" mt={"16px"}>
            Already have an Account?
            <Link
              to="/login"
              style={{
                textDecoration: "none",
                color: "#C9AF7F",
                marginLeft: "5px",
              }}
            >
              Login
            </Link>
          </Typography>

          <Box sx={{ marginTop: "16px" }}>
            <FormControl
              sx={{ marginTop: "32px", width: "100%" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Display Name
              </InputLabel>
              <OutlinedInput
                id="name"
                aria-describedby="outlined-weight-helper-text"
                onChange={(e) => setName(e.target.value)}
                label="Display Name"
              />
            </FormControl>
            <FormControl
              sx={{ marginTop: "32px", width: "100%" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Work Email
              </InputLabel>
              <OutlinedInput
                id="email"
                endAdornment={<InputAdornment position="end">@</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                onChange={(e) => setEmail(e.target.value)}
                label="Work Email"
              />
            </FormControl>
            <FormControl
              sx={{ marginTop: "32px", width: "100%" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <Logo name="hide" width="14px" height="14px" />
                      ) : (
                        <Logo name="show" width="14px" height="14px" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <Button
              variant="contained"
              sx={{
                width: "182px",
                height: "48px",
                marginTop: "32px",
                marginLeft: "52%",
                color: "white",
                background: "linear-gradient(to bottom, #373B59, #050A30)",
                borderRadius: "16px",
              }}
              onClick={register}
            >
              Sign Up
            </Button>
            <Box sx={{ margin: "32px 0 0 30px" }}>
              <Typography variant="caption">
                Protected by reCAPTCHA and subject to the Google{" "}
                <Button>
                  <Typography variant="caption" sx={{ color: "#C9AF7F" }}>
                    Privacy Policy
                  </Typography>
                </Button>
                and
                <Button>
                  <Typography variant="caption" sx={{ color: "#C9AF7F" }}>
                    Terms of Service.
                  </Typography>
                </Button>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
