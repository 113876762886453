// Basic
import * as React from "react";
import { useState } from "react";
//External func components
import { Dropbox } from "dropbox";
import { getDatabase, ref, update } from "firebase/database";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
// MUI
import {
  Typography,
  Button,
  Container,
  FormControl,
  LinearProgress,
  Box,
  List,
  ListItem,
} from "@mui/material";
export default function Art({ data, comapnyid }) {
  const uid = localStorage.getItem("uid");
  const db = getDatabase();
  const [files, setFiles] = useState([]);
  const [formDisplay, setFormDisplay] = useState("block");
  const [progressDisplay, setProgressDisplay] = useState("none");
  async function uploadimg(e) {
    const file = e.target.files[0];
    if (file) {
      setFiles([...files, file]);
    }

    uploadFile(file);
    e.target.value = null;
  }

  function uploadFile(file) {
    setFormDisplay("none");
    setProgressDisplay("block");

    var file = file;

    const dbx = new Dropbox({
      clientId: process.env.REACT_APP_DROPBOX_CLIENT_KEY,
      clientSecret: process.env.REACT_APP_DROPBOX_CLIENT_SECRET_KEY,
      refreshToken: process.env.REACT_APP_DROPBOX_REFRESH_TOKEN_KEY,
    });

    dbx.filesListFolder({ path: "" }).then((response) => {
      const found = response.result.entries.find((name) => {
        return name[".tag"] === "folder" && name.name === comapnyid;
      });
      if (found !== undefined) {
        dbx
          .filesUpload({
            path: `/${comapnyid}/` + file.name,
            contents: file,

            autorename: true,
          })

          .then(function (response) {
            writeUserData(response.result);
          })
          .catch(function (error) {
            console.error(error);
            enqueueSnackbar("Error, Please try again", {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
                autoHideDuration: 6000,
              },
            });
          });
      } else {
        dbx.filesCreateFolderV2({
          autorename: false,
          path: "",
        });
        dbx
          .filesUpload({
            path: `/${comapnyid}/` + file.name,
            contents: file,
          })

          .then(function (response) {
            writeUserData(response.result);
          })
          .catch(function (error) {
            enqueueSnackbar("Error, Please try again", {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
                autoHideDuration: 6000,
              },
            });
          });
      }
    });
  }

  function writeUserData(result) {
    update(
      ref(db, "users/" + uid + `/${comapnyid}` + "/uploads" + `/${result.id}`),
      {
        FileId: result.id,
        FileName: result.name,
        FileSize: result.size,
        FileDate: result.client_modified,
      }
    )
      .then(() => {
        enqueueSnackbar("File uploaded successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
            autoHideDuration: 6000,
          },
        });
        setFormDisplay("block");
        setProgressDisplay("none");
      })
      .catch((err) => {
        enqueueSnackbar("Error, Please try again", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
            autoHideDuration: 6000,
          },
        });
      });
  }

  return (
    <Container maxWidth="md">
      <SnackbarProvider maxSnack={3} />
      <Typography
        variant="h5"
        href="/"
        sx={{
          mt: 10,
          mb: 5,
          fontSize: "32px",
          textAlign: "left",
          fontFamily: "Inter",
          fontWeight: "600",
          color: "rgb(55, 59, 89)",
        }}
      >
        Submit Artworks for your Organization
      </Typography>
      <Typography
        variant="h5"
        href="/"
        sx={{
          mt: 10,
          mb: 5,
          fontSize: "24px",
          textAlign: "left",
          fontFamily: "Inter",
          fontWeight: "600",
          color: "rgb(55, 59, 89)",
        }}
      >
        Guidance
      </Typography>

      <List>
        <ListItem>
          <Typography
            variant="p"
            sx={{
              mt: 2,
              mb: 5,
              fontSize: "18px",
              textAlign: "left",
              fontFamily: "Inter",
              fontWeight: "400",
              color: "rgb(55, 59, 89)",
            }}
          >
            Please upload one or multiple Zip files containing all images or
            text gathered from artists, as well as one Excel file with the
            following information about artists:
            <br />
            <br />
            Artist First Name
            <br />
            Artist Surname
            <br />
            Artist Age
            <br />
            Artist Country
            <br />
            Email address (you can have the same email address for multiple
            entries as long as the entire set of the above fields create a
            unique record. We accept only one submission per individual)
            <br />
            <br />
            Optional field
            <br />
            <br />
            Description of the art (text that explains the art. Not required but
            encouraged. This can be in any language)
          </Typography>
        </ListItem>
        <ListItem>
          <Typography
            variant="p"
            sx={{
              mt: 2,
              mb: 5,
              fontSize: "18px",
              textAlign: "left",
              fontFamily: "Inter",
              fontWeight: "400",
              color: "rgb(55, 59, 89)",
            }}
          >
            Max file size per upload is 150mb. If you have a bigger file you can
            upload in multiple chunks.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography
            variant="p"
            sx={{
              mt: 2,
              mb: 5,
              fontSize: "18px",
              textAlign: "left",
              fontFamily: "Inter",
              fontWeight: "400",
              color: "rgb(55, 59, 89)",
            }}
          >
            Artworks file formats we accept: <br />
            <br />
            For Images:
            <br />
            JPEG, JPG, PNG, TIFF, RAW.
            <br />
            <br />
            For Text:
            <br />
            TXT.
          </Typography>
        </ListItem>
      </List>
      <Box display={formDisplay}>
        <FormControl
          sx={{ marginTop: "32px", width: "100%" }}
          variant="outlined"
        >
          <label htmlFor="upload-photo">
            <input
              style={{ display: "none" }}
              id="upload-photo"
              name="upload-photo"
              type="file"
              onChange={uploadimg}
            />

            <Button color="primary" variant="contained" component="span">
              Upload File
            </Button>
          </label>
        </FormControl>
      </Box>
      <Box sx={{ width: "100%", mt: "20px" }} display={progressDisplay}>
        <LinearProgress />
      </Box>
    </Container>
  );
}
