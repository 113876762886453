import React from "react";

import Check from "./Check";
import Logo from "./Logo";
import LogoLong from "./LogoLong";
import Show from "./Show";
import Hide from "./Hide";
import Org from "./Org";
import File from "./File";
import NewLogo from "./NewLogo";
import Plus from "./Plus";

const Icon = (props) => {
  switch (props.name) {
    case "check":
      return <Check w={props.width} h={props.height} c={props.color} />;

    case "logo":
      return <Logo w={props.width} h={props.height} c={props.color} />;
    case "logo_long":
      return <LogoLong w={props.width} h={props.height} c={props.color} />;
    case "show":
      return <Show w={props.width} h={props.height} c={props.color} />;
    case "hide":
      return <Hide w={props.width} h={props.height} c={props.color} />;
    case "org":
      return <Org w={props.width} h={props.height} c={props.color} />;
    case "newlogo":
      return <NewLogo w={props.width} h={props.height} c={props.color} />;
    case "plus":
      return <Plus w={props.width} h={props.height} c={props.color} />;
    case "file":
      return (
        <File
          w={props.width}
          h={props.height}
          c={props.color}
          e={props.extension}
        />
      );
    default:
      return;
  }
};

export default Icon;
