import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function Row(row) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.row.Name}
        </TableCell>
        <TableCell align="center">{row.row.Surname}</TableCell>
        <TableCell align="center">{row.row.Age}</TableCell>
        <TableCell align="center">{row.row.Country}</TableCell>
        <TableCell align="center">{row.row.Org}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Text Submission
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      Share a Story about your community ancestral relationship
                      with the moon
                    </TableCell>

                    <TableCell align="left">
                      Offer any Wisdom and Guidance for humans planning to
                      establish settlements on the Moon
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={row.row.Text1}>
                    <TableCell align="left">{row.row.Text1}</TableCell>

                    <TableCell align="left">{row.row.Text2}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(data) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell sx={{ color: "#c9af7f" }}>Name</TableCell>
            <TableCell sx={{ color: "#c9af7f" }} align="center">
              Surname
            </TableCell>
            <TableCell sx={{ color: "#c9af7f" }} align="center">
              Age
            </TableCell>
            <TableCell sx={{ color: "#c9af7f" }} align="center">
              Country
            </TableCell>
            <TableCell sx={{ color: "#c9af7f" }} align="center">
              Org
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.data.Bulkdata
            ? Object.keys(data.data.Bulkdata).map((item, i) =>
                data.data.Bulkdata[item].data.map((x) => (
                  <Row key={x.Name} row={x} />
                ))
              )
            : Object.keys(data.data.Singledata).map((row, i) => (
                <Row key={i} row={data.data.Singledata[row]} />
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
