import React from "react";

const SVG = ({ w, h, c }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 1107 974"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="565" cy="167" r="117" stroke={c} strokeWidth="20" />
    <circle cx="243" cy="344" r="117" stroke={c} strokeWidth="20" />
    <circle cx="553" cy="508" r="117" stroke={c} strokeWidth="20" />
    <circle cx="209" cy="684" r="117" stroke={c} strokeWidth="20" />
    <circle cx="553" cy="811" r="117" stroke={c} strokeWidth="20" />
    <circle cx="897" cy="487" r="117" stroke={c} strokeWidth="20" />
    <line
      x1="329.078"
      y1="262.801"
      x2="458.078"
      y2="207.801"
      stroke={c}
      strokeWidth="20"
    />
    <line x1="563" y1="281" x2="563" y2="390" stroke={c} strokeWidth="20" />
    <line
      x1="655.263"
      y1="239.204"
      x2="833.263"
      y2="382.204"
      stroke={c}
      strokeWidth="20"
    />
    <line
      x1="665.652"
      y1="491.006"
      x2="780.652"
      y2="487.006"
      stroke={c}
      strokeWidth="20"
    />
    <line
      x1="342.18"
      y1="401.916"
      x2="455.18"
      y2="453.916"
      stroke={c}
      strokeWidth="20"
    />
    <line
      x1="498.505"
      y1="258.259"
      x2="289.505"
      y2="596.259"
      stroke={c}
      strokeWidth="20"
    />
    <line
      x1="313.559"
      y1="435.828"
      x2="484.559"
      y2="718.828"
      stroke={c}
      strokeWidth="20"
    />
    <line x1="563" y1="624" x2="563" y2="696" stroke={c} strokeWidth="20" />
    <line
      x1="655.828"
      y1="751.032"
      x2="826.828"
      y2="575.032"
      stroke={c}
      strokeWidth="20"
    />
    <line
      x1="306.728"
      y1="738.379"
      x2="440.728"
      y2="776.379"
      stroke={c}
      strokeWidth="20"
    />
    <circle cx="565" cy="138" r="36" fill={c} />
    <path
      d="M519 201C519 192.716 525.716 186 534 186H596C604.284 186 611 192.716 611 201V231H519V201Z"
      fill={c}
    />
    <circle cx="897" cy="458" r="36" fill={c} />
    <path
      d="M851 521C851 512.716 857.716 506 866 506H928C936.284 506 943 512.716 943 521V551H851V521Z"
      fill={c}
    />
    <circle cx="553" cy="479" r="36" fill={c} />
    <path
      d="M507 542C507 533.716 513.716 527 522 527H584C592.284 527 599 533.716 599 542V572H507V542Z"
      fill={c}
    />
    <circle cx="243" cy="315" r="36" fill={c} />
    <path
      d="M197 378C197 369.716 203.716 363 212 363H274C282.284 363 289 369.716 289 378V408H197V378Z"
      fill={c}
    />
    <circle cx="209" cy="655" r="36" fill={c} />
    <path
      d="M163 718C163 709.716 169.716 703 178 703H240C248.284 703 255 709.716 255 718V748H163V718Z"
      fill={c}
    />
    <circle cx="553" cy="782" r="36" fill={c} />
    <path
      d="M507 845C507 836.716 513.716 830 522 830H584C592.284 830 599 836.716 599 845V875H507V845Z"
      fill={c}
    />
  </svg>
);
export default SVG;
