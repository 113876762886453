export const fiel = [
  {
    label: "Name",
    key: "Name",
    alternateMatches: ["first name", "first"],
    fieldType: {
      type: "input",
    },
    example: "Stephanie",
    validations: [
      {
        rule: "required",
        errorMessage: "Name is required",
      },
    ],
  },
  {
    label: "Surname",
    key: "Surname",
    alternateMatches: ["last name", "last"],
    fieldType: {
      type: "input",
    },
    example: "Thompson",
    validations: [],
  },
  {
    label: "Age",
    key: "Age",
    alternateMatches: ["age", "years old"],
    fieldType: {
      type: "input",
    },
    example: "55",
    validations: [
      {
        rule: "required",
        errorMessage: "Age is required",
      },
    ],
  },
  {
    label: "Country",
    key: "Country",
    alternateMatches: ["city", "town"],
    fieldType: {
      type: "input",
    },
    example: "USA",
    validations: [
      {
        rule: "required",
        errorMessage: "Country is required",
      },
    ],
  },
  {
    label: "Email",
    key: "Email",
    alternateMatches: ["contact"],
    fieldType: {
      type: "input",
    },
    example: "info@lunarc.org",
    validations: [
      {
        rule: "required",
        errorMessage: "Email is required",
      },
    ],
  },

  {
    label: "Indigenous or Tribal Community",
    key: "Org",
    alternateMatches: [
      "org",
      "Indigenous or Tribal Community",
      "indigenous or tribal community",
      "organization",
      "indigenous",
      "tribal",
    ],
    fieldType: {
      type: "input",
    },
    example: "Lunarc",
    validations: [],
  },
  {
    label: "File Name",
    key: "Image",
    alternateMatches: ["file", "document", "image", "file name"],
    fieldType: {
      type: "input",
    },
    example: "artwork.jpg",
    validations: [
      {
        rule: "required",
        errorMessage: "File Name is required",
      },
    ],
  },
  {
    label:
      "Share a Story about your community ancestral relationship with the moon",
    key: "Text1",
    alternateMatches: [
      "Share a Story about your community ancestral relationship with the moon",
    ],
    fieldType: {
      type: "input",
    },
    example:
      "Share a Story about your community ancestral relationship with the moon",
    validations: [
      {
        rule: "required",
        errorMessage: "This Text is required",
      },
    ],
  },
  {
    label: "Question 1 Translation if not in english",
    key: "TranslationText1",
    alternateMatches: ["Question 1 Translation if not in english"],
    fieldType: {
      type: "input",
    },
    example: "Question 1 Translation if not in english",
    validations: [],
  },
  {
    label:
      "Offer any Wisdom and Guidance for humans planning to establish settlements on the Moon",
    key: "Text2",
    alternateMatches: [
      "Offer any Wisdom and Guidance for humans planning to establish settlements on the Moon",
    ],
    fieldType: {
      type: "input",
    },
    example:
      "Offer any Wisdom and Guidance for humans planning to establish settlements on the Moon",
    validations: [
      {
        rule: "required",
        errorMessage: "This Text is required",
      },
    ],
  },
  {
    label: "Question 2 Translation if not in english",
    key: "TranslationText2",
    alternateMatches: ["Question 2 Translation if not in english"],
    fieldType: {
      type: "input",
    },
    example: "Question 2 Translation if not in english",
    validations: [],
  },
];
