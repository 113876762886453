import React, { useState, useEffect } from "react";

import MenuIcon from "@mui/icons-material/Menu";

import Brightness2Icon from "@mui/icons-material/Brightness2";

import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Tooltip,
  MenuItem,
} from "@mui/material";

import Logo from "../../Assets/SVG/index";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Checkadmin, CheckAllData } from "../../Services/utils";
export default function Navbar() {
  const [isadmin, setIsadmin] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("Auth Token");
    localStorage.removeItem("ID Token");
    localStorage.removeItem("uid");
    localStorage.removeItem("isNewUser");
    navigate("/login");
  };
  const pages = ["Home"];
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    let admin;
    (async () => {
      admin = await Checkadmin();
    })().then(() => {
      if (admin === true) {
        setIsadmin(true);
      }
    });
  }, []);

  return (
    <AppBar
      display="flex"
      sx={{
        backgroundColor: "#FFF",
        zIndex: "98",
        overflow: "hidden",
        height: "7vh",
        minHeight: "65px",
        boxShadow: "none",
      }}
    >
      <Container
        maxWidth="full"
        style={{ paddingLeft: "64px", paddingRight: "64px" }}
      >
        <Toolbar disableGutters>
          <Link to={"/home"} style={{ textDecoration: "none" }}>
            <Logo name={"newlogo"} height="40" color="black" />
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box>
          <Brightness2Icon
            sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
          />

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem>
                <Link to={"/settings"} style={{ textDecoration: "none" }}>
                  <Typography
                    textAlign="center"
                    sx={{
                      fontSize: "18px",
                      textAlign: "center",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "rgb(55, 59, 89)",
                    }}
                  >
                    Settings
                  </Typography>
                </Link>
              </MenuItem>
              {isadmin ? (
                <MenuItem>
                  <Link to={"/admin"} style={{ textDecoration: "none" }}>
                    <Typography
                      textAlign="center"
                      sx={{
                        fontSize: "18px",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontWeight: "400",
                        color: "rgb(55, 59, 89)",
                      }}
                    >
                      Admin
                    </Typography>
                  </Link>
                </MenuItem>
              ) : null}
              <MenuItem onClick={handleLogout}>
                <Typography
                  textAlign="center"
                  sx={{
                    fontSize: "18px",
                    textAlign: "center",
                    fontFamily: "Inter",
                    fontWeight: "400",
                    color: "rgb(55, 59, 89)",
                  }}
                >
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
