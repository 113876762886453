// Basic
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Singledatasubmit, storeimg } from "../../Services/utils";
// Components
//External func components
import { SnackbarProvider, enqueueSnackbar } from "notistack";

import { useForm } from "react-hook-form";
// MUI
import {
  Typography,
  Box,
  LinearProgress,
  TextField,
  Stack,
  Button,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";

import { MuiFileInput } from "mui-file-input";

export default function Submit() {
  const [files, setFiles] = useState([]);
  const [videos, setVideos] = useState([]);
  const [progresspercent, setProgresspercent] = useState(0);
  const [progresspercentvideo, setProgresspercentvideo] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const handleChange = (newFile) => {
    setFiles(newFile);
  };

  const handleChangeVideo = (newVideo) => {
    setVideos(newVideo);
  };

  async function uploadimg(e) {
    const file = e.target.files[0];
    if (file) {
      setFiles(file);
    }
    e.target.value = null;
  }

  async function onSubmit(data) {
    const imagestored = await storeimg(files, setProgresspercent);
    const videostored = await storeimg(videos, setProgresspercentvideo);

    const singlesub = await Singledatasubmit(data, imagestored, videostored);
    if (singlesub === true) {
      enqueueSnackbar("Informations successfully updated", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
          autoHideDuration: 6000,
        },
      });
    } else {
      enqueueSnackbar("Error, Please try again", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
          autoHideDuration: 6000,
        },
      });
    }
  }

  return (
    <Box padding={5}>
      <SnackbarProvider maxSnack={3} />
      <Box>
        <Typography variant="h5" sx={{ mb: "20px", color: "#c9af7f" }}>
          Submit Your Contribution
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              "& > :not(style)": {
                m: 1,
                width: { xs: "100%", lg: "48%" },
              },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              label={"Artist Name"}
              {...register("name", {
                required: true,
              })}
            ></TextField>
            <TextField
              // sx={{ width: "45%", marginTop: "20px" }}
              label={"Artist Surname"}
              {...register("surname", {
                required: false,
              })}
            ></TextField>
            <TextField
              sx={{ width: "45%", marginTop: "20px" }}
              label={"Artist Age"}
              {...register("age", {
                required: true,
              })}
            ></TextField>

            <TextField
              sx={{ width: "45%", marginTop: "20px" }}
              label={"Country"}
              {...register("country", {
                required: true,
              })}
            ></TextField>
            <TextField
              sx={{ width: "45%", marginTop: "20px" }}
              label={"Email"}
              {...register("email", {
                required: true,
              })}
            ></TextField>
            <TextField
              sx={{ width: "45%", marginTop: "20px" }}
              label={"Indigenous or Tribal Community"}
              {...register("org", {})}
            ></TextField>
            <TextField
              sx={{ width: "45%", marginTop: "20px" }}
              label={
                "Share a Story about your community ancestral relationship with the moon"
              }
              {...register("q1", {
                required: true,
              })}
              multiline
              helperText={"Max 500 words"}
              rows={4}
            ></TextField>

            <TextField
              sx={{ width: "100%", mt: "30px" }}
              label={
                "Offer any Wisdom and Guidance for humans planning to establish settlements on the Moon"
              }
              {...register("q2", {
                required: true,
              })}
              multiline
              helperText={"Max 500 words"}
              rows={4}
            ></TextField>
            <Typography variant="h6" sx={{ mb: "20px", color: "#c9af7f" }}>
              If not in English please provide a translation
            </Typography>

            <TextField
              sx={{ width: "45%", marginTop: "20px" }}
              label={
                "Share a Story about your community ancestral relationship with the moon"
              }
              {...register("q3", {})}
              multiline
              helperText={"Max 500 words"}
              rows={4}
            ></TextField>

            <TextField
              sx={{ width: "100%", mt: "30px" }}
              label={
                "Offer any Wisdom and Guidance for humans planning to establish settlements on the Moon"
              }
              {...register("q4", {})}
              multiline
              helperText={"Max 500 words"}
              rows={4}
            ></TextField>
          </Box>
          <Typography variant="h6" sx={{ mb: "20px", color: "#c9af7f" }}>
            Media Section
          </Typography>

          <Stack
            direction={{ xs: "column", lg: "row" }}
            spacing={3}
            sx={{ m: 1 }}
          >
            <Box
              sx={{
                width: { xs: "100%", lg: "48%" },
              }}
            >
              <MuiFileInput
                size="small"
                variant="outlined"
                placeholder="Click to Upload an Image file"
                value={files}
                onChange={handleChange}
                sx={{ width: "100%" }}
                InputProps={{
                  inputProps: {
                    accept: ".png, .jpeg, .jpg",
                  },
                  startAdornment: <AttachFileIcon />,
                }}
                clearIconButtonProps={{
                  title: "Remove",
                  children: <CloseIcon fontSize="small" />,
                }}
              />
              <Box sx={{ mt: "10px" }}>
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                >
                  {`${progresspercent}%`}
                </Typography>

                <LinearProgress variant="determinate" value={progresspercent} />
              </Box>
            </Box>

            <Box
              sx={{
                width: { xs: "100%", lg: "48%" },
              }}
            >
              <MuiFileInput
                size="small"
                variant="outlined"
                placeholder="Click to Upload a Video file"
                value={videos}
                onChange={handleChangeVideo}
                sx={{ width: "100%" }}
                InputProps={{
                  inputProps: {
                    accept: "video/*",
                  },
                  startAdornment: <AttachFileIcon />,
                }}
                clearIconButtonProps={{
                  title: "Remove",
                  children: <CloseIcon fontSize="small" />,
                }}
              />
              <Box sx={{ mt: "10px" }}>
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                >
                  {`${progresspercentvideo}%`}
                </Typography>

                <LinearProgress
                  variant="determinate"
                  value={progresspercentvideo}
                />
              </Box>
            </Box>
          </Stack>
          <Box
            sx={{
              "& > :not(style)": {
                m: 1,
                width: { xs: "100%", lg: "48%" },
                marginTop: "30px",
              },
            }}
            noValidate
            autoComplete="off"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                maxWidth: "300px",
                height: "50px",

                background: "#c9af7f",
              }}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
