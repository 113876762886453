import React from "react";
import { DocusealForm } from "@docuseal/react";
import { getDatabase, ref, update } from "firebase/database";
const db = getDatabase();
function Writedata(data) {
  let uid = localStorage.getItem("uid");

  update(ref(db, "users/" + uid), {
    mou: true,
    moudata: data,
    Bulkdata: "",
    Singledata: "",
  })
    .then((res) => {
      console.log(res);
      console.log("done");
    })
    .catch((err) => {
      console.log(err);
    });
}

export default function Signature() {
  return (
    <div className="app">
      <DocusealForm
        src="https://docuseal.co/d/Sw6rw2Ui8gFJvy"
        withSendCopyButton="false"
        completedButton={{ title: "Back Home", url: "https://app.lunarc.org" }}
        onComplete={(data) => Writedata(data)}
        data-remember-signature="false"
      />
    </div>
  );
}
