import React from "react";
import { useState, useEffect } from "react";
import Logo from "../../Assets/SVG/index";
import { useNavigate } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

import {
  getAuth,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import backgroundIMG from "../../Assets/Images/lunarc.jpeg";
import { Link } from "react-router-dom";

import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Grid,
} from "@mui/material";
export default function ResetPass({}) {
  const auth = getAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const reset = (e) => {
    e.preventDefault();
    // setError("");

    // Create a new user with email and password using firebase
    sendPasswordResetEmail(auth, email)
      .then(function () {
        enqueueSnackbar("Password reset email sent, Please check your inbox", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          autoHideDuration: 18000,
        });
      })
      .catch(function (err) {
        enqueueSnackbar("Error, please try again", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          autoHideDuration: 18000,
        });
      });
  };

  return (
    <Box>
      <Box
        sx={{
          minWidth: {
            xs: "100vw", // theme.spacing(3)
            md: "30vw", // theme.spacing(5)
            lg: "50vw", // theme.spacing(5)
          },
          maxWidth: {
            xs: "100vw", // theme.spacing(3)
            md: "30vw", // theme.spacing(5)
            lg: "50vw", // theme.spacing(5)
          },
          minHeight: {
            xs: "30vh", // theme.spacing(3)
            md: "100vh", // theme.spacing(5)
          },
          float: {
            sm: "none",
            md: "left",
          },

          backgroundImage: `url(${backgroundIMG})`,
          backgroundColor: "black",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
        }}
      ></Box>

      <Box
        sx={{
          minWidth: {
            xs: "100vw", // theme.spacing(3)
            md: "70vw", // theme.spacing(5)
            lg: "50vw", // theme.spacing(5)
          },
          maxWidth: {
            xs: "100vw", // theme.spacing(3)
            md: "70vw", // theme.spacing(5)
            lg: "50vw", // theme.spacing(5)
          },
          minHeight: {
            xs: "70vh", // theme.spacing(3)
            md: "100vh", // theme.spacing(5)
          },
          float: {
            sm: "none",
            md: "left",
          },
          color: "#050A30",
          background: "white",
        }}
      >
        <SnackbarProvider maxSnack={3} />
        <Box
          sx={{
            width: "380px",
            margin: "0 auto",
            paddingTop: {
              xs: "3% ",
              md: "20%",
            },
          }}
        >
          <Typography sx={{ typography: { xs: "h4" }, fontWeight: "bold" }}>
            Reset Password
          </Typography>

          <Box sx={{ marginTop: "16px" }}>
            <FormControl
              sx={{ marginTop: "32px", width: "100%" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Email
              </InputLabel>
              <OutlinedInput
                id="email"
                endAdornment={<InputAdornment position="end">@</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                onChange={(e) => setEmail(e.target.value)}
                label="Work Email"
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Link to="/login" style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      width: "182px",
                      color: "gray",
                      marginTop: "45px",
                      borderRadius: "16px",
                    }}
                  >
                    Back to Login
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  onClick={reset}
                  sx={{
                    width: "182px",
                    height: "48px",
                    marginTop: "32px",
                    marginLeft: "52%",
                    color: "white",
                    background: "linear-gradient(to bottom, #373B59, #050A30)",
                    borderRadius: "16px",
                  }}
                >
                  Reset Password
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
