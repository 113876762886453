import React, { useState, useEffect } from "react";
import StickyHeadTable from "./Table";
import Navbar from "../../Components/Navbar";
import { Box, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { Checkadmin, CheckAllData } from "../../Services/utils";

export default function Admin() {
  const navigate = useNavigate();
  const [admindata, setAdmindata] = useState();

  useEffect(() => {
    let admin;
    (async () => {
      admin = await Checkadmin();
      setAdmindata(await CheckAllData());
    })().then(() => {
      if (!admin) {
        navigate("/");
      }
    });
  }, []);

  if (admindata) {
    return (
      <Box
        sx={{
          backgroundColor: "#EEEF2",
        }}
        height={"100%"}
        padding={"64px"}
      >
        <Navbar />

        <StickyHeadTable data={admindata} />
      </Box>
    );
  } else {
    return <Box>no data</Box>;
  }
}
