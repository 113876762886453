// Basic
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkuser, Checkmou, CheckUserData } from "../../Services/utils";
import Navbar from "../../Components/Navbar";
import { useSelector } from "react-redux";
// Components
//External func components
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { getDatabase, ref, update } from "firebase/database";
import { useForm } from "react-hook-form";
// MUI
import { Typography, Button, TextField, Box } from "@mui/material";

export default function Settings() {
  const uid = localStorage.getItem("uid");
  const db = getDatabase();

  const navigate = useNavigate();
  const [mou, setMou] = React.useState();
  const [userdata, setUserdata] = React.useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Name: "s",
    },
  });
  useEffect(() => {
    const authToken = checkuser();
    if (!authToken.authToken) {
      navigate("/login");
    }

    (async () => {
      let x = await Checkmou();
      setMou(x);
    })();

    CheckUserData().then((value) => {
      setUserdata(value);
    });
  }, []);
  const onSubmit = (data) => {
    update(ref(db, "users/" + uid), {
      Name: data.name ? data.name : userdata.Name,
      Surname: data.surname ? data.surname : userdata.Surname,
      Email: data.Email ? data.Email : userdata.Email,
      Phone: data.PhoneNumber ? data.PhoneNumber : userdata.Phone,
      Org_Name: data.organizationName
        ? data.organizationName
        : userdata.Org_Name,
      Org_Website: data.organizationWebsite
        ? data.organizationWebsite
        : userdata.Org_Website,
    })
      .then(() => {
        enqueueSnackbar("Informations successfully updated", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
            autoHideDuration: 6000,
          },
        });
        if (mou === false) {
          navigate("/Signature");
        } else {
          navigate("/home");
        }
      })
      .catch((err) => {
        enqueueSnackbar("Error, Please try again", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
            autoHideDuration: 6000,
          },
        });
      });
  };
  return (
    <div>
      {mou === false ? null : <Navbar />}

      <SnackbarProvider maxSnack={4} />
      <Box
        backgroundColor={"#F5F5F5"}
        padding={"50px"}
        minHeight={"70vh"}
        sx={{ borderRadius: "15px" }}
        marginTop={mou === false ? "15px" : "90px"}
      >
        <Typography
          sx={{
            mb: 5,
            fontSize: "34px",
            textAlign: "left",

            color: "black",
          }}
        >
          Your Informations
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              "& > :not(style)": {
                m: 1,
                width: { xs: "100%", lg: "48%" },
              },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              label={userdata.Name ? userdata.Name : "Name"}
              {...register("name", {
                required: userdata.Name ? false : true,
              })}
              error={errors.name}
            ></TextField>

            <TextField
              label={userdata.Surname ? userdata.Surname : "Surname"}
              {...register("surname", {
                required: userdata.Surname ? false : true,
              })}
              error={errors.surname}
            ></TextField>
            <TextField
              label={userdata.Email ? userdata.Email : "Email"}
              {...register("Email", {
                required: userdata.Email ? false : true,
              })}
              error={errors.Email}
            ></TextField>
            <TextField
              label={userdata.Phone ? userdata.Phone : "Phone Number"}
              {...register("PhoneNumber", {
                required: userdata.Phone ? false : true,
              })}
              error={errors.PhoneNumber}
            ></TextField>
          </Box>
          <Typography
            variant="h5"
            href="/"
            sx={{
              mt: 5,
              mb: 5,
              fontSize: "20px",
              textAlign: "left",
              fontFamily: "Inter",
              fontWeight: "600",
              color: "rgb(55, 59, 89)",
            }}
          >
            Are you part of an Organization?
          </Typography>
          <Box
            sx={{
              "& > :not(style)": { m: 1, width: { xs: "100%", lg: "48%" } },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              label={
                userdata.Org_Name ? userdata.Org_Name : "Organization Name"
              }
              {...register("organizationName", {
                required: userdata.Org_Name ? false : true,
              })}
              error={errors.organizationName}
            ></TextField>
            <TextField
              label={
                userdata.Org_Website
                  ? userdata.Org_Website
                  : "Organization Website"
              }
              {...register("organizationWebsite", {
                required: userdata.Org_Website ? false : true,
              })}
              error={errors.organizationWebsite}
            ></TextField>
          </Box>

          <Button
            type="submit"
            variant="contained"
            sx={{ m: 1, width: "150px", height: "50px" }}
          >
            Save
          </Button>
        </form>
      </Box>
    </div>
  );
}
