// Basic
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// Components
import Art from "./Art";
import Prev from "./Prev";
import Info from "./Info";
import Navbar from "../../Components/Navbar";
//External func components
import { getDatabase, ref, set, onValue } from "firebase/database";

// MUI
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import EditIcon from "@mui/icons-material/Edit";
import BackupIcon from "@mui/icons-material/Backup";

function ResponsiveDrawer(props) {
  let { state } = useLocation();
  const drawerWidth = 240;
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [content, setContent] = React.useState("prev");
  const uid = localStorage.getItem("uid");
  const db = getDatabase();
  const [data, setData] = React.useState();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  useEffect(() => {
    let authToken = localStorage.getItem("Auth Token");

    if (!authToken) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    const starCountRef = ref(db, "users/" + uid + `/${state["id"]}`);
    onValue(starCountRef, (snapshot) => {
      setData(snapshot.val());
    });
  }, []);

  const drawer = (
    <List>
      <ListItem disablePadding sx={{ px: "5px" }}>
        <ListItemButton
          onClick={() => setContent("prev")}
          sx={{
            borderRadius: "14px",
          }}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ px: "5px" }}>
        <ListItemButton
          onClick={() => setContent("info")}
          sx={{
            borderRadius: "14px",
            mt: "5px",
          }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Organizations Info" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ px: "5px" }}>
        <ListItemButton
          onClick={() => setContent("art")}
          sx={{
            borderRadius: "14px",

            mt: "5px",
          }}
        >
          <ListItemIcon>
            <BackupIcon />
          </ListItemIcon>
          <ListItemText primary="Art Submission" />
        </ListItemButton>
      </ListItem>
    </List>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar />
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          display: { sm: "none" },
          marginLeft: "5px",

          height: "40px",
          marginTop: "80px",
        }}
      >
        <MenuIcon />
      </IconButton>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          zIndex: "9",
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },

            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              paddingTop: "90px",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: "white",
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          minHeight: "100vh",
        }}
      >
        {(() => {
          switch (content) {
            case "art":
              return <Art data={data} comapnyid={state["id"]} />;
            case "info":
              return <Info oldData={data} id={state["id"]} />;
            case "prev":
              return <Prev data={data} id={state["id"]} />;
            default:
              return <Prev data={data} id={state["id"]} />;
          }
        })()}
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
