import React from "react";
import { useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import Logo from "../../Assets/SVG/index";
import { useNavigate } from "react-router-dom";
import backgroundIMG from "../../Assets/Images/lunarc.jpeg";
import { Link } from "react-router-dom";
import { updateUID } from "../../Redux/accountSlice";
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Button,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const dispatch = useDispatch();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleAction = (id) => {
    const authentication = getAuth();
    signInWithEmailAndPassword(authentication, email, password)
      .then((response) => {
        localStorage.setItem("uid", response.user.uid);
        dispatch(updateUID(response.user.uid));
        localStorage.setItem(
          "Auth Token",
          response._tokenResponse.refreshToken
        );
        localStorage.setItem("ID Token", response._tokenResponse.idToken);
        navigate("/home");
      })
      .catch((error) => {
        if (error.code === "auth/wrong-password") {
          enqueueSnackbar("Error, wrong Username or Password", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            autoHideDuration: 18000,
          });
        }
        if (error.code === "auth/user-not-found") {
          enqueueSnackbar("Error, wrong Username or Password", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
              autoHideDuration: 18000,
            },
          });
        }
      });
  };

  return (
    <Box>
      <Box
        sx={{
          minWidth: {
            xs: "100vw", // theme.spacing(3)
            md: "30vw", // theme.spacing(5)
            lg: "50vw", // theme.spacing(5)
          },
          maxWidth: {
            xs: "100vw", // theme.spacing(3)
            md: "30vw", // theme.spacing(5)
            lg: "50vw", // theme.spacing(5)
          },
          minHeight: {
            xs: "30vh", // theme.spacing(3)
            md: "100vh", // theme.spacing(5)
          },
          float: {
            sm: "none",
            md: "left",
          },

          backgroundImage: `url(${backgroundIMG})`,
          backgroundColor: "black",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
        }}
      ></Box>

      <Box
        sx={{
          minWidth: {
            xs: "100vw", // theme.spacing(3)
            md: "70vw", // theme.spacing(5)
            lg: "50vw", // theme.spacing(5)
          },
          maxWidth: {
            xs: "100vw", // theme.spacing(3)
            md: "70vw", // theme.spacing(5)
            lg: "50vw", // theme.spacing(5)
          },
          minHeight: {
            xs: "70vh", // theme.spacing(3)
            md: "100vh", // theme.spacing(5)
          },
          float: {
            sm: "none",
            md: "left",
          },
          color: "#050A30",
          background: "white",
        }}
      >
        <Box
          sx={{
            width: "380px",
            margin: "0 auto",
            paddingTop: {
              xs: "3% ",
              md: "20%",
            },
          }}
        >
          <SnackbarProvider maxSnack={3} />

          <Typography sx={{ typography: { xs: "h4" }, fontWeight: "bold" }}>
            Sign In
          </Typography>

          <Typography variant="body1" mt={"16px"}>
            Don’t have an account yet?
            <Link
              to="/signup"
              style={{
                textDecoration: "none",
                color: "#C9AF7F",
                marginLeft: "5px",
              }}
            >
              Register
            </Link>
          </Typography>

          <Box sx={{ marginTop: "10px" }}>
            <FormControl
              sx={{ marginTop: "32px", width: "100%" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Work Email
              </InputLabel>
              <OutlinedInput
                id="email"
                endAdornment={<InputAdornment position="end">@</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                onChange={(e) => setEmail(e.target.value)}
                label="Work Email"
              />
            </FormControl>
            <FormControl
              sx={{ marginTop: "32px", width: "100%" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <Logo name="hide" width="14px" height="14px" />
                      ) : (
                        <Logo name="show" width="14px" height="14px" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Link to="/ResetPassword" style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      width: "182px",

                      color: "gray",
                      marginTop: "45px",
                      borderRadius: "16px",
                    }}
                  >
                    Reset password
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  onClick={handleAction}
                  sx={{
                    width: "182px",
                    height: "48px",
                    marginTop: "32px",
                    marginLeft: "52%",
                    color: "white",
                    background: "linear-gradient(to bottom, #373B59, #050A30)",
                    borderRadius: "16px",
                  }}
                >
                  Sign In
                </Button>
              </Grid>
            </Grid>

            <Box sx={{ margin: "32px 0 0 30px" }}>
              <Typography variant="caption">
                Protected by reCAPTCHA and subject to the Google{" "}
                <Button>
                  <Typography variant="caption" sx={{ color: "#CC4165" }}>
                    Privacy Policy
                  </Typography>
                </Button>
                and
                <Button>
                  <Typography variant="caption" sx={{ color: "#CC4165" }}>
                    Terms of Service.
                  </Typography>
                </Button>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
