import React from "react";

const SVG = ({ w, h, c, e }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 24 24"
    fill={c}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-1"
      d="M16.5,22h-9a3,3,0,0,1-3-3V5a3,3,0,0,1,3-3h6.59a1,1,0,0,1,.7.29l4.42,4.42a1,1,0,0,1,.29.7V19A3,3,0,0,1,16.5,22Z"
      fill="#f44336"
    />
    <path
      class="cls-2"
      d="M18.8,7.74H15.2a1.5,1.5,0,0,1-1.5-1.5V2.64a.55.55,0,0,1,.94-.39L19.19,6.8A.55.55,0,0,1,18.8,7.74Z"
      fill="#ff8a80"
    />
    <text x="5" y="18" fontSize={"4px"} fontWeight={"bold"} fill={"#FFF"}>
      {e}
    </text>
  </svg>
);

export default SVG;
