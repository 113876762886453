import React from "react";

const SVG = ({ w, h, c }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 225 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M112.66 0L79.2969 33.3622L112.66 66.7249L146.021 33.3622L112.66 0ZM112.66 61.5921L84.4298 33.3622L112.66 5.13242L140.89 33.3622L112.66 61.5921Z"
      fill="#C9AF7F"
    />
    <path
      d="M108.525 22.1156L106.925 26.8146L106.142 25.0101L103.145 28.6536V38.4601L106.142 42.1037V31.5477L107.231 33.9656L108.525 29.9817V44.9982L111.522 48.6416L111.556 18.4722L108.525 22.1156Z"
      fill="#C9AF7F"
    />
    <path
      d="M116.787 35.0552H119.988V32.0587H116.787V26.8146L119.988 30.6966L121.963 28.3811L113.791 18.4722V48.6416L116.787 44.9982V35.0552Z"
      fill="#C9AF7F"
    />
    <path
      d="M0 95.5969C0.540434 95.5534 0.755882 95.5324 1.1447 95.5324C1.51176 95.5324 1.72794 95.5534 2.24588 95.5969C2.39677 92.963 2.46206 91.9046 2.67751 90.0475C2.85088 88.4712 2.95824 87.6079 3.2825 85.4266C4.40544 87.5862 4.83707 88.4502 5.59295 90.0258C6.47795 91.8828 6.99663 92.9412 8.16237 95.5969C8.42134 95.5752 8.52943 95.5752 8.72384 95.5752C8.91825 95.5752 9.00457 95.5752 9.26355 95.5969C10.3865 92.963 10.8827 91.9046 11.7466 90.0693C12.5025 88.493 12.9341 87.6079 14.0999 85.4484C14.4024 87.5862 14.5322 88.4502 14.6831 90.0258C14.8775 91.8828 14.9421 92.963 15.0502 95.5969C15.9141 95.5324 16.2377 95.5324 16.8637 95.5324C17.4905 95.5324 17.814 95.5324 18.678 95.5969C18.2028 93.0058 18.0302 91.9481 17.7712 90.091C17.4905 88.1041 17.2308 86.2906 17.0581 84.6497C16.9072 83.1815 16.8209 82.2747 16.6911 80.0506C16.1949 80.0934 15.9787 80.0934 15.6552 80.0934C15.3527 80.0934 15.18 80.0934 14.7049 80.0506C13.7764 82.3175 13.3447 83.246 12.6752 84.6715C12.0491 86.0534 10.9262 88.4066 10.0412 90.0475L9.54428 90.976L9.06914 90.091C8.1406 88.3631 6.99663 86.0534 6.36987 84.7143C5.67927 83.2678 5.24765 82.3175 4.27559 80.0288C3.77869 80.0934 3.585 80.0934 3.23898 80.0934C2.9372 80.0934 2.74279 80.0934 2.28941 80.0288C2.095 82.2747 1.98691 83.2025 1.81426 84.6925C1.59809 86.3987 1.40368 88.1694 1.08014 90.0258C0.755882 91.8828 0.561471 92.963 0 95.5969Z"
      fill="#FBF9F5"
    />
    <path
      d="M21.2673 87.9956C21.2673 92.6165 24.5919 95.7909 29.4072 95.7909C34.3734 95.7909 37.764 92.5302 37.764 87.8019C37.764 83.0947 34.3734 79.834 29.4935 79.834C24.5708 79.834 21.2673 83.0947 21.2673 87.9956ZM29.4289 81.1724C30.4656 81.1724 31.5015 81.4966 32.3437 82.0799C33.8119 83.1375 34.4597 84.9511 34.4597 88.0609C34.4597 92.1631 32.6462 94.5381 29.4935 94.5381C26.3198 94.5381 24.5273 92.1631 24.5273 87.8883C24.5273 83.5481 26.2545 81.1724 29.4289 81.1724Z"
      fill="#FBF9F5"
    />
    <path
      d="M41.174 80.0503C41.2821 82.2955 41.3467 83.2457 41.3902 84.714C41.433 85.6208 41.4113 86.6138 41.4113 87.6287C41.4113 89.3777 41.3902 91.2783 41.3249 92.9627C41.3039 93.5452 41.2821 94.4309 41.2386 95.5967C41.671 95.5531 41.8436 95.5531 42.1243 95.5531C42.4261 95.5531 42.6205 95.5531 43.0529 95.5967C42.9876 92.9627 42.9448 91.8825 42.9448 90.069V89.205L42.9665 86.6791V84.6922L43.7652 85.599C44.7155 86.6791 46.5291 88.7734 47.5657 90.0255C49.0339 91.7534 49.9189 92.8974 52.0785 95.5967C52.5536 95.5531 52.7691 95.5531 53.0933 95.5531C53.4169 95.5531 53.633 95.5531 54.1082 95.5967C54.0219 92.9837 54.0219 91.9478 54.0001 90.0472C54.0001 89.2486 53.9783 88.4281 53.9783 87.6077C53.9783 85.7724 54.0001 84.0444 54.0647 82.4471C54.0864 81.9074 54.1082 81.1297 54.151 80.0503C53.6976 80.0931 53.5467 80.0931 53.2225 80.0931C52.8989 80.0931 52.7045 80.0931 52.2729 80.0503C52.3592 82.2955 52.3803 83.224 52.402 84.6922C52.402 85.4263 52.4238 86.1605 52.4238 87.0027C52.4238 87.5641 52.402 88.1474 52.402 88.7088V91.0193L50.4158 88.7299C48.9686 87.0462 47.911 85.8152 46.9824 84.714C45.7949 83.2675 45.0391 82.339 43.2683 80.0503C42.7721 80.0931 42.5777 80.1149 42.1889 80.1149C41.8436 80.1149 41.671 80.0931 41.174 80.0503Z"
      fill="#FBF9F5"
    />
    <path
      d="M56.5032 95.5967C57.0218 95.5531 57.2373 95.5321 57.5826 95.5321C57.9497 95.5321 58.1658 95.5531 58.6838 95.5967C59.008 94.6681 59.1161 94.2793 59.3533 93.6533C59.7639 92.6167 59.9794 92.0124 60.6272 90.4796C61.9445 90.4361 62.506 90.4361 63.5644 90.4361C64.3848 90.4361 64.8382 90.4361 66.0257 90.4578C66.5008 91.9261 66.6953 92.4875 66.997 93.5669C67.2132 94.2793 67.3213 94.6464 67.5803 95.5967C68.487 95.5531 68.8541 95.5321 69.5236 95.5321C70.1714 95.5321 70.5385 95.5531 71.4453 95.5967C71.0136 94.6464 70.8192 94.2147 70.5813 93.6315C70.0206 92.3149 69.3075 90.3925 68.4225 87.8666C67.3213 84.6922 66.6953 82.7278 66.1338 80.8707C66.0903 80.6981 66.0039 80.4174 65.8958 80.0503C65.2698 80.1366 65.0108 80.1366 64.5357 80.1366C64.0823 80.1366 63.8016 80.1149 63.1538 80.0503L62.8948 80.7409C62.3551 82.1874 61.2539 84.8866 60.0229 87.8666C58.8999 90.5441 58.0367 92.5521 57.3454 93.934C57.1082 94.4309 56.9348 94.7762 56.5032 95.5967ZM65.5505 88.8815C64.5357 88.9033 64.1251 88.9033 63.4345 88.9033C62.7004 88.9033 62.2688 88.9033 61.2539 88.8597L63.6072 83.2457L65.5505 88.8815Z"
      fill="#FBF9F5"
    />
    <path
      d="M86.6451 81.1731C85.673 80.6551 85.2849 80.5042 84.5936 80.2881C83.6433 80.0073 82.6502 79.8564 81.6354 79.8564C76.6046 79.8564 73.3011 82.9873 73.3011 87.8237C73.3011 92.6607 76.6046 95.8134 81.5708 95.8134C82.9955 95.8134 84.3346 95.5537 85.4358 95.0575C85.6085 94.993 85.8674 94.8413 86.2127 94.6687C86.2345 94.0855 86.2563 93.8265 86.3208 93.4166C86.3644 93.0278 86.3861 92.7899 86.5152 92.1856L86.3208 92.0993C84.7663 93.7837 83.557 94.388 81.8516 94.388C78.6126 94.388 76.6046 91.9266 76.6046 87.9963C76.6046 83.7432 78.4182 81.2601 81.5491 81.2601C83.3416 81.2601 84.8961 82.1234 85.9755 83.786L86.2127 83.7215C86.2563 83.1382 86.2991 82.901 86.3644 82.4687C86.4289 82.037 86.4724 81.7781 86.6451 81.1731Z"
      fill="#FBF9F5"
    />
    <path
      d="M89.2137 87.9956C89.2137 92.6165 92.5383 95.7909 97.3536 95.7909C102.32 95.7909 105.71 92.5302 105.71 87.8019C105.71 83.0947 102.32 79.834 97.44 79.834C92.5173 79.834 89.2137 83.0947 89.2137 87.9956ZM97.3754 81.1724C98.412 81.1724 99.4479 81.4966 100.29 82.0799C101.758 83.1375 102.406 84.9511 102.406 88.0609C102.406 92.1631 100.593 94.5381 97.44 94.5381C94.2663 94.5381 92.4738 92.1631 92.4738 87.8883C92.4738 83.5481 94.201 81.1724 97.3754 81.1724Z"
      fill="#FBF9F5"
    />
    <path
      d="M117.044 95.5967C117.821 95.5531 118.145 95.5321 118.685 95.5321C119.289 95.5321 119.635 95.5531 120.477 95.5967C120.348 93.3725 120.304 92.5086 120.304 90.8677V88.2119H121.017C121.751 88.2119 122.701 88.1902 123.371 88.2337C123.781 88.2555 124.428 88.2983 125.292 88.3418C125.206 87.9094 125.206 87.7368 125.206 87.435C125.206 87.1543 125.206 86.9809 125.27 86.5928C123.349 86.7219 122.507 86.7437 121.082 86.7437H120.304C120.282 85.5562 120.282 85.0382 120.282 84.1743C120.282 83.4184 120.282 82.9004 120.304 81.6912H121.104C122.507 81.6912 123.371 81.7129 125.443 81.7993C125.4 81.4104 125.4 81.2378 125.4 80.9571C125.4 80.6546 125.4 80.4602 125.443 80.0503C123.349 80.1366 122.356 80.1577 121.19 80.1577C120.218 80.1577 119.03 80.1366 118.016 80.0931C117.821 80.0931 117.498 80.0713 117.044 80.0503C117.173 82.2955 117.217 83.224 117.26 84.7357C117.281 85.7506 117.281 86.7219 117.281 87.7368C117.281 89.5721 117.238 91.4944 117.152 93.2217C117.131 93.7614 117.087 94.5383 117.044 95.5967Z"
      fill="#FBF9F5"
    />
    <path
      d="M127.84 87.9956C127.84 92.6165 131.165 95.7909 135.98 95.7909C140.946 95.7909 144.337 92.5302 144.337 87.8019C144.337 83.0947 140.946 79.834 136.066 79.834C131.144 79.834 127.84 83.0947 127.84 87.9956ZM136.002 81.1724C137.039 81.1724 138.074 81.4966 138.917 82.0799C140.385 83.1375 141.033 84.9511 141.033 88.0609C141.033 92.1631 139.219 94.5381 136.066 94.5381C132.893 94.5381 131.1 92.1631 131.1 87.8883C131.1 83.5481 132.827 81.1724 136.002 81.1724Z"
      fill="#FBF9F5"
    />
    <path
      d="M147.746 80.0288C147.876 82.3393 147.897 83.2895 147.897 84.736V87.4999L147.876 90.4146C147.854 93.3511 150.855 95.8131 154.397 95.8131C158.111 95.8131 160.918 93.1356 160.918 89.6377V84.736C160.918 83.1162 160.961 82.2529 161.09 80.0288C160.659 80.0934 160.464 80.0934 160.119 80.0934C159.838 80.0934 159.644 80.0934 159.233 80.0506C159.341 82.2529 159.385 83.1379 159.385 84.736V88.0613C159.385 89.465 159.277 90.5009 159.039 91.2785C158.499 93.0493 157.01 94.1069 155.023 94.1069C153.058 94.1069 151.482 93.0275 151.136 91.4512C151.007 90.8897 150.963 90.2419 150.963 88.5793V84.736C150.963 83.4404 151.028 82.4256 151.201 80.0506C150.38 80.1151 150.057 80.1151 149.495 80.1151C148.891 80.1151 148.567 80.1151 147.746 80.0288Z"
      fill="#FBF9F5"
    />
    <path
      d="M164.825 80.0503C164.933 82.2955 164.998 83.2457 165.041 84.714C165.084 85.6208 165.062 86.6138 165.062 87.6287C165.062 89.3777 165.041 91.2783 164.976 92.9627C164.955 93.5452 164.933 94.4309 164.89 95.5967C165.322 95.5531 165.495 95.5531 165.775 95.5531C166.077 95.5531 166.272 95.5531 166.704 95.5967C166.639 92.9627 166.596 91.8825 166.596 90.069V89.205L166.618 86.6791V84.6922L167.416 85.599C168.367 86.6791 170.18 88.7734 171.217 90.0255C172.685 91.7534 173.57 92.8974 175.73 95.5967C176.205 95.5531 176.42 95.5531 176.744 95.5531C177.068 95.5531 177.284 95.5531 177.759 95.5967C177.673 92.9837 177.673 91.9478 177.651 90.0472C177.651 89.2486 177.629 88.4281 177.629 87.6077C177.629 85.7724 177.651 84.0444 177.716 82.4471C177.738 81.9074 177.759 81.1297 177.802 80.0503C177.349 80.0931 177.198 80.0931 176.874 80.0931C176.55 80.0931 176.356 80.0931 175.924 80.0503C176.01 82.2955 176.031 83.224 176.053 84.6922C176.053 85.4263 176.075 86.1605 176.075 87.0027C176.075 87.5641 176.053 88.1474 176.053 88.7088V91.0193L174.067 88.7299C172.62 87.0462 171.562 85.8152 170.634 84.714C169.446 83.2675 168.69 82.339 166.919 80.0503C166.423 80.0931 166.229 80.1149 165.84 80.1149C165.495 80.1149 165.322 80.0931 164.825 80.0503Z"
      fill="#FBF9F5"
    />
    <path
      d="M181.752 95.5958C182.637 95.5522 183.004 95.5312 183.566 95.5312C184.041 95.5312 184.689 95.5522 185.574 95.5958C186.221 95.6175 186.805 95.6393 187.323 95.6393C190.583 95.6393 192.958 94.451 194.492 92.076C195.355 90.7376 195.83 89.1613 195.83 87.5415C195.83 82.8995 192.764 80.0059 187.841 80.0059C187.28 80.0059 186.632 80.0276 185.92 80.0704C184.861 80.1139 184.084 80.1567 183.609 80.1567C183.026 80.1567 182.637 80.1357 181.752 80.0704C181.881 82.3163 181.925 83.2448 181.968 84.7566C182.011 85.6851 181.989 86.5919 181.989 87.4987C181.989 89.4203 181.946 91.4935 181.86 93.3071C181.838 93.825 181.795 94.5591 181.752 95.5958ZM186.567 81.4959C190.627 81.4959 192.527 83.482 192.527 87.7576C192.527 90.3053 191.642 92.314 190.065 93.3499C189.137 93.9549 188.208 94.2138 186.654 94.2138C186.157 94.2138 185.703 94.1921 185.034 94.1493L185.012 91.0184V84.7348L185.034 81.5604C185.639 81.5176 186.049 81.4959 186.567 81.4959Z"
      fill="#FBF9F5"
    />
    <path
      d="M199.285 95.597C200.083 95.5535 200.407 95.5324 200.99 95.5324C201.573 95.5324 201.897 95.5535 202.717 95.597C202.588 93.3729 202.545 92.4661 202.545 90.8898V88.6439C203.581 89.9185 203.97 90.4582 204.661 91.4513C205.589 92.8114 206.043 93.5891 207.166 95.597C208.181 95.5535 208.569 95.5324 209.303 95.5324C209.994 95.5324 210.405 95.5535 211.398 95.597C209.692 93.6319 209.066 92.8332 207.9 91.2786C207.144 90.2855 206.518 89.4215 206.022 88.6439C205.914 88.4713 205.827 88.3421 205.633 88.0614C206.389 87.9315 206.842 87.8024 207.317 87.5862C208.612 86.9602 209.498 85.513 209.498 84.0448C209.498 81.5617 207.555 79.9854 204.466 79.9854C203.97 79.9854 203.431 80.0071 202.847 80.0506C201.875 80.1152 201.206 80.137 200.839 80.137C200.451 80.137 200.04 80.1152 199.306 80.0506C199.414 82.2958 199.457 83.2026 199.5 84.7579C199.522 85.7937 199.522 86.7876 199.522 87.8235C199.522 89.6588 199.478 91.5593 199.392 93.222C199.371 93.7617 199.328 94.5386 199.285 95.597ZM202.545 81.5834C202.977 81.5189 203.236 81.4971 203.603 81.4971C205.438 81.4971 206.302 82.4039 206.302 84.3255C206.302 86.3124 205.287 87.3273 203.322 87.3273C203.063 87.3273 202.869 87.3055 202.545 87.262V81.5834Z"
      fill="#FBF9F5"
    />
    <path
      d="M211.309 80.0503C212.669 82.1663 213.21 83.0085 214.117 84.5841C215.066 86.204 215.563 87.1325 216.794 89.4212V90.1553C216.794 91.1702 216.772 92.3149 216.729 93.2434C216.708 93.7831 216.664 94.5383 216.556 95.5967C217.398 95.5321 217.744 95.5321 218.327 95.5321C218.931 95.5321 219.255 95.5321 220.098 95.5967C219.925 93.3725 219.86 92.4875 219.86 90.9112V88.9461C220.961 86.9163 221.393 86.0959 222.214 84.6712C223.099 83.1377 223.638 82.2309 224.999 80.0503C224.373 80.0931 224.135 80.1149 223.682 80.1149C223.293 80.1149 223.056 80.0931 222.451 80.0503C221.48 82.3172 221.069 83.224 220.292 84.7575C219.86 85.599 219.644 86.0531 219.018 87.1753C218.327 86.0096 218.09 85.5344 217.636 84.714C216.815 83.2022 216.384 82.2955 215.347 80.0503C214.418 80.1366 214.051 80.1366 213.339 80.1366C212.648 80.1366 212.26 80.1366 211.309 80.0503Z"
      fill="#FBF9F5"
    />
  </svg>
);
export default SVG;
