import * as React from "react";
import Logo from "../../Assets/SVG/index";
import { Card, Divider, Box, Typography, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
export default function Homecard({ logo, name, desc, status, link }) {
  return (
    <Card
      sx={{
        width: "400px",
        height: "600px",
        backgroundColor: "#3c3c3c",
        color: "white",
        borderRadius: "20px",
        textAlign: "center",
        border: "solid",
        borderWidth: "1px",
        borderColor: "#fff",
        boxShadow: "rgba(5, 10, 48, 0.05) 0px 3px 5px",
      }}
    >
      {logo ? (
        <Box height={"200px"} sx={{ backgroundColor: "#262626" }}>
          <img alt="example" height={"200px"} src={logo} />
        </Box>
      ) : null}
      <Box p={"24px"}>
        <Box height={"280px"}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "25px",
              textAlign: "center",
              color: "#c9af7f",
              fontWeight: "800",
            }}
          >
            {name}
          </Typography>

          {desc ? (
            <Typography
              variant="h6"
              sx={{
                fontSize: "15px",
                textAlign: "justify",

                fontWeight: "500",

                padding: "20px 0 10px 0",
              }}
            >
              {desc}
            </Typography>
          ) : null}
        </Box>
        <Divider color="white" />

        <Grid container spacing={2} marginTop={"5px"}>
          <Grid item xs={link ? 6 : 12}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "18px",
                textAlign: "center",
                fontWeight: "800",
                color: "#c9af7f",
              }}
            >
              {"Status: "}
              <Typography
                variant="span"
                sx={{
                  color: status === "Closed" ? "#ff8b8b" : "#8ff78f",
                  fontSize: "15px",
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                {status}
              </Typography>
            </Typography>
          </Grid>
          {link ? (
            <Grid item xs={link ? 4 : 6} borderLeft={"1px solid #ccc"}>
              <Link
                to={"Mission"}
                state={link}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    color: "white",
                    background: "#419341",
                    borderRadius: "10px",
                  }}
                >
                  Submit
                </Button>
              </Link>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Card>
  );
}
