// Basic
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CheckUserData } from "../../Services/utils";
// Components
import Navbar from "../../Components/Navbar";
import Info from "./Info";
import Submit from "./Submit";
import MultySubmit from "./MultySubmit";
//External func components
import { getDatabase } from "firebase/database";

// MUI
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import EditIcon from "@mui/icons-material/Edit";
import BackupIcon from "@mui/icons-material/Backup";

export default function Mission({}) {
  let { state } = useLocation();
  const drawerWidth = 300;
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [content, setContent] = React.useState("info");
  const [userdata, setUserdata] = React.useState([]);

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleListItemClick = (event, index, content) => {
    setSelectedIndex(index);
    setContent(content);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  useEffect(() => {
    let authToken = localStorage.getItem("Auth Token");

    if (!authToken) {
      navigate("/login");
    } else {
      CheckUserData().then((value) => {
        setUserdata(value);
      });
    }
  }, []);

  const items = [
    { content: "info", Icon: HomeIcon, text: "Dashboard", index: 0 },
    { content: "submit", Icon: BackupIcon, text: "Single Submit", index: 1 },
    {
      content: "multy",
      Icon: BackupIcon,
      text: "Bulk Submit",
      index: 2,
    },
  ];

  const drawer = (
    <div>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          margin: "0 auto",
          width: "50px",
          height: "50px",
          background: "#c9af7f",
          background:
            "linear-gradient(90deg, rgba(205,65,183,1) 0%, rgba(205,65,101,1) 33%, rgba(229,116,79,1) 66%, rgba(255,226,111,1) 100%)",
          borderRadius: "100%",
          marginTop: { xs: "50px", sm: "0" },
        }}
      >
        {userdata.Name ? (
          <Typography variant="h5" color="white">
            {userdata.Name.charAt(0).toUpperCase()}
          </Typography>
        ) : (
          <AccountCircleIcon sx={{ color: "white" }} />
        )}
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={"15px"}
      >
        <Typography variant="h6">
          {userdata.Name !== undefined
            ? userdata.Name + " " + userdata.Surname
            : ""}
        </Typography>
      </Box>

      <List sx={{ pt: "40px" }}>
        {items.map(({ content, Icon, text, index }) => (
          <ListItem disablePadding sx={{ px: "5px", pt: "10px" }}>
            <ListItemButton
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index, content)}
              sx={{
                borderRadius: "14px",
              }}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar />
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          display: { md: "none" },
          marginLeft: "5px",

          height: "40px",
          marginTop: "80px",
        }}
      >
        <MenuIcon />
      </IconButton>
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { sm: 0 },
          zIndex: "9",
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            borderRight: "none",
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: "none",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              paddingTop: "90px",
              borderRight: "none",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: "#F2F2F2",

          borderRadius: "30px",
          marginTop: "65px",
          width: { sm: `calc(100% - ${drawerWidth}px )` },
          height: { xs: `100%`, xl: `calc(100% - ${0}px)` },
        }}
      >
        {(() => {
          switch (content) {
            case "info":
              return <Info data={userdata} />;
            case "submit":
              return <Submit />;
            case "multy":
              return <MultySubmit />;
            default:
              return <Info data={userdata} />;
          }
        })()}
      </Box>
    </Box>
  );
}
