import { Routes, Route, useNavigate } from "react-router-dom";

import Home from "../pages/Home";
import Login from "../pages/Login";
import ResetPass from "../pages/ResetPass";
import Organization from "../pages/Organization";
import SetPassword from "../pages/SetPassword";
import Signup from "../pages/Signup";
import Mission from "../pages/Missions/Mission";
import Signature from "../pages/Signature";
import Settings from "../pages/Settings/Settings";
import Admin from "../pages/Admin/Admin";
import OrgData from "../pages/Admin/OrgData";
export default function RootNavigation() {
  let navigate = useNavigate();

  return (
    <Routes>
      <Route path="/login" element={<Login title="Login" />} />
      <Route
        path="/ResetPassword"
        element={<ResetPass title="ResetPassword" />}
      />
      <Route
        path="/SetPassword"
        element={<SetPassword title="SetPassword" />}
      />
      <Route path="/Signup" element={<Signup title="Signup" />} />
      <Route path="/Signature" element={<Signature title="Signature" />} />
      <Route
        path="/organization"
        element={<Organization title="Organization" />}
      />
      <Route path="/Mission" element={<Mission title="Mission" />} />
      <Route path="/Settings" element={<Settings title="Settings" />} />
      <Route path="/Admin" element={<Admin title="Admin" />} />
      <Route path="/orgdata/:id" element={<OrgData title="OrgData" />} />

      <Route path="*" element={<Home title="Home" />} />
    </Routes>
  );
}
