import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import {
  Box,
  Typography,
  Stack,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { GetUserData } from "../../Services/utils";

export default function Admin() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [userdata, setUserdata] = useState();

  useEffect(() => {
    (async () => {
      setUserdata(await GetUserData(id));
    })();
  }, []);

  if (userdata) {
    return (
      <Box
        sx={{
          backgroundColor: "#EEEF2",
        }}
        height={"100%"}
        paddingX={"64px"}
      >
        <Navbar />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={"120px"}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <Typography>{userdata.Name}</Typography>
            <Typography> {userdata.Surname}</Typography>
            <Typography> {userdata.Email}</Typography>
            <Typography> {userdata.Org_Name}</Typography>
          </Stack>
        </Box>

        <Typography
          sx={{ fontSize: "40px", paddingY: "20px", fontWeight: "800" }}
        >
          {" "}
          Bulk Data
        </Typography>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: "80vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align={"center"}>Name</TableCell>
                  <TableCell align={"center"}>Surname</TableCell>
                  <TableCell align={"center"}>Org Name</TableCell>
                  <TableCell align={"center"}>Age</TableCell>
                  <TableCell align={"center"}>Country</TableCell>
                  <TableCell align={"center"}>
                    Share a Story about your community ancestral relationship
                    with the moon
                  </TableCell>
                  <TableCell align={"center"}>
                    Offer any Wisdom and Guidance for humans planning to
                    establish settlements on the Moon
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(userdata.Bulkdata).map((item, i) =>
                  Object.keys(userdata.Bulkdata[item]).map((x, is) =>
                    userdata.Bulkdata[item][x].map((xx) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                        <TableCell align={"center"}>{xx.Name}</TableCell>
                        <TableCell align={"center"}>{xx.Surname}</TableCell>
                        <TableCell align={"center"}>{xx.Org}</TableCell>
                        <TableCell align={"center"}>{xx.Age}</TableCell>
                        <TableCell align={"center"}>{xx.Country}</TableCell>
                        <TableCell align={"center"}>{xx.Text1}</TableCell>
                        <TableCell align={"center"}>{xx.Text2}</TableCell>
                      </TableRow>
                    ))
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Typography
          sx={{ fontSize: "40px", paddingY: "20px", fontWeight: "800" }}
        >
          {" "}
          Single Data
        </Typography>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: "80vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align={"center"}>Name</TableCell>
                  <TableCell align={"center"}>Surname</TableCell>
                  <TableCell align={"center"}>Org Name</TableCell>
                  <TableCell align={"center"}>Age</TableCell>
                  <TableCell align={"center"}>Country</TableCell>
                  <TableCell align={"center"}>
                    Share a Story about your community ancestral relationship
                    with the moon
                  </TableCell>
                  <TableCell align={"center"}>
                    Offer any Wisdom and Guidance for humans planning to
                    establish settlements on the Moon
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(userdata.Singledata).map((item, i) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    <TableCell align={"center"}>
                      {userdata.Singledata[item].Name}
                    </TableCell>
                    <TableCell align={"center"}>
                      {userdata.Singledata[item].Surname}
                    </TableCell>
                    <TableCell align={"center"}>
                      {userdata.Singledata[item].Org}
                    </TableCell>
                    <TableCell align={"center"}>
                      {userdata.Singledata[item].Age}
                    </TableCell>
                    <TableCell align={"center"}>
                      {userdata.Singledata[item].Country}
                    </TableCell>
                    <TableCell align={"center"}>
                      {userdata.Singledata[item].Text1}
                    </TableCell>
                    <TableCell align={"center"}>
                      {userdata.Singledata[item].Text2}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    );
  } else {
    return <Box>no data</Box>;
  }
}
