import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import Card from "../../Components/Card";
import { SnackbarProvider } from "notistack";
import { Container, Box, Typography, Stack } from "@mui/material";
import { checkuser, Checkmou, userstatus } from "../../Services/utils";
import { updateProfile } from "../../Redux/accountSlice";
import { useDispatch } from "react-redux";
import roket from "../../Assets/Images/roket.png";
import roket2 from "../../Assets/Images/roket2.png";
import roket3 from "../../Assets/Images/roket3.png";

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const missions = [
    {
      logo: roket,
      name: "Lunar Mission 1",
      desc: "Our inaugural lunar mission began with a simple yet audacious idea: to empower the underrepresented to lead the charge into space. We offered an unprecedented opportunity for artists from marginalized communities to be pioneers in a historic journey to the Moon, a chance for everyone to participate in something larger than themselves.",
      status: "Closed",
    },
    {
      logo: roket2,
      name: "Deep Space Mission",
      desc: "Once we finalized the Lunar Community Art Gallery collection, an extraordinary opportunity arose: a chance to include a small sample of the collection in a capsule destined for an asteroid flyby mission. This capsule will embark on an interstellar trajectory, journeying for the next 30 years into the farthest reaches of our solar system!",
      status: "Closed",
    },
    {
      logo: roket3,
      name: "Lunar Mission 2",
      desc: "Expanding upon our initial lunar mission, we plan to engage millions worldwide over the next few years and weave a tapestry of narratives, ideas, and solutions that illuminate our profound interconnectedness, guiding us toward a sustainable and equitable future. Our journey begins with Indigenous and Tribal communities.",
      status: "Open",
      link: "Mission3",
    },
  ];

  useEffect(() => {
    let mou;
    const authToken = checkuser();
    if (!authToken.authToken) {
      navigate("/login");
    }

    userstatus(navigate);
    (async () => {
      mou = await Checkmou();
    })().then(() => {
      if (mou === false) {
        navigate("/Settings");
      } else {
        dispatch(updateProfile(mou));
      }
    });
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#EEEF2",
      }}
      height={"100%"}
      padding={"64px"}
    >
      <SnackbarProvider maxSnack={3} />
      <Navbar />

      <Box
        backgroundColor={"#fff"}
        //padding={"50px"}
        minHeight={"70vh"}
        sx={{ borderRadius: "15px", marginTop: "15px" }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            sx={{
              mb: 5,
              fontSize: "60px",
              textAlign: "left",
              fontWeight: "800",

              letterSpacing: "20px",
            }}
            className="gradient-text "
          >
            Missions
          </Typography>
        </Box>

        <Stack
          direction={"row"}
          useFlexGap
          flexWrap="wrap"
          spacing={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {missions.map(({ logo, name, desc, status, link }) => (
            <Card
              logo={logo}
              name={name}
              desc={desc}
              status={status}
              link={link}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
}

export default Home;
