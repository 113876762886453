// Basic
import * as React from "react";
// Components
import CollapsibleTable from "./Table.js";
//External func components
import { PieChart } from "@mui/x-charts/PieChart";
// MUI
import { Grid, Box, Stack, Typography, CircularProgress } from "@mui/material";

function countwords(data) {
  let singlesubfirst = 0;
  let singlesubsecond = 0;
  let multisubfirst = 0;
  let multisubsecond = 0;

  if (data.data.Singledata) {
    Object.keys(data.data.Singledata).map(
      (item, i) => (
        (singlesubfirst += data.data.Singledata[item].Text1.length),
        (singlesubsecond += data.data.Singledata[item].Text2.length)
      )
    );
  }
  if (data.data.Bulkdata) {
    Object.keys(data.data.Bulkdata).map((item, i) =>
      data.data.Bulkdata[item].data.map(
        (x) => (
          (multisubfirst += x.Text1.length), (multisubsecond += x.Text2.length)
        )
      )
    );
  }
  return singlesubfirst + singlesubsecond + multisubfirst + multisubsecond;
}

export default function Info(data) {
  if (data.data) {
    return (
      <Grid container paddingBottom={"50px"}>
        <Grid xs={12} marginBottom={"30px"}>
          <Box
            marginTop={"50px"}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <Typography
                sx={{
                  mb: 5,
                  fontSize: "60px",
                  textAlign: "left",
                  fontWeight: "800",
                  letterSpacing: "20px",
                  color: "#c9af7f",
                }}
              >
                Submissions
              </Typography>
            </Stack>
          </Box>
        </Grid>

        <Grid
          xs={12}
          px={5}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width={{ xs: "100%", lg: "100%" }}
            sx={{
              //backgroundColor: "#fff",

              height: "300px",
              borderRadius: "30px",
            }}
          >
            <Stack
              spacing={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box width={{ xs: "100%", lg: "100%" }} maxWidth={600}>
                <PieChart
                  series={[
                    {
                      data: [
                        {
                          id: 0,
                          value: countwords(data),
                          label: "Characters",
                        },
                        { id: 1, value: 0, label: "Images" },
                        { id: 2, value: 1, label: "Free Space" },
                      ],
                      innerRadius: 30,
                      outerRadius: 100,
                      cornerRadius: 5,
                    },
                  ]}
                  height={250}
                />
              </Box>
            </Stack>
          </Box>
        </Grid>
        {data.data ? (
          <Grid
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="auto"
          >
            <Box
              width={{ xs: "350px", sm: "90%" }}
              sx={{
                height: "500px",
                borderRadius: "30px",
              }}
            >
              {data.data.Bulkdata ? (
                <CollapsibleTable data={data.data} />
              ) : data.data.Singledata ? (
                <CollapsibleTable data={data.data} />
              ) : null}
            </Box>
          </Grid>
        ) : null}
      </Grid>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
}
