import {
  getDatabase,
  ref,
  set,
  onValue,
  update,
  push,
  DataSnapshot,
} from "firebase/database";
import { getAuth } from "firebase/auth";
import {
  getStorage,
  getDownloadURL,
  uploadBytesResumable,
  ref as sRef,
} from "firebase/storage";

import { v4 as uuidv4 } from "uuid";
import { firebaseConfig } from "../Services/Firebase/firebase-config"; // needed to make the whole app work not clear why
import { resolve } from "path-browserify";

const db = getDatabase();
let uid = localStorage.getItem("uid");
const storage = getStorage();

function checkuser() {
  let authToken = localStorage.getItem("Auth Token");
  return {
    authToken,
  };
}

function userstatus(navigate) {
  const auth = getAuth();

  const checkAuthState = async () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
      } else {
        localStorage.removeItem("Auth Token");
        localStorage.removeItem("ID Token");
        localStorage.removeItem("uid");

        navigate("/login");
      }
    });
  };
  checkAuthState();
}

function Writedata(data) {
  update(ref(db, "users/" + uid), {
    mou: true,
    moudata: data,
    Bulkdata: "",
    Singledata: "",
  })
    .then((res) => {
      console.log(res);
      console.log("done");
    })
    .catch((err) => {
      console.log(err);
    });
}

function Checkmou() {
  const uid = localStorage.getItem("uid");
  const starCountRef = ref(db, "users/" + uid);
  let response;
  return new Promise((resolve) => {
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();

      if (
        data === null ||
        data.mou === null ||
        data.mou === undefined ||
        data.mou === false
      ) {
        resolve((response = false));
      } else if (data.mou === true) {
        resolve((response = data));
      }
    });
  });
}

function CheckUserData() {
  const uid = localStorage.getItem("uid");
  const starCountRef = ref(db, "users/" + uid);
  let response;
  return new Promise((resolve, reject) => {
    onValue(starCountRef, (snapshot) => {
      response = snapshot.val();

      if (response) {
        resolve(response);
      }
    });
  });
}

function Singledatasubmit(data, imagestored, videostored) {
  return new Promise((resolve, reject) => {
    push(ref(db, "users/" + uid + "/Singledata"), {
      Name: data.name,
      Surname: data.surname,
      Age: data.age,
      Country: data.country,
      Email: data.email,
      Org: data.org,
      Text1: data.q1,
      Text2: data.q2,
      Translation1: data.q3,
      Translation1: data.q4,
      Image: imagestored ? imagestored : "",
      Video: videostored ? videostored : "",
    })
      .then(() => {
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function Bulkdatasubmit(data) {
  return new Promise((resolve, reject) => {
    push(ref(db, "users/" + uid + "/Bulkdata"), {
      data,
    })
      .then(() => {
        resolve(true);
      })
      .catch((err) => {
        resolve(err);
      });
  });
}

async function storeimg(files, setProgresspercent) {
  if (files.length !== 0) {
    let extension = files.name.substr(files.name.lastIndexOf(".") + 1);
    return new Promise(function (resolve, reject) {
      const storageRef = sRef(storage, `data/${uid}/${uuidv4()}.${extension}`);
      const uploadTask = uploadBytesResumable(storageRef, files);
      uploadTask.on(
        "state_changed",
        function (snapshot) {
          var progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          setProgresspercent(progress);
        },
        function error(err) {
          reject();
        },
        function complete() {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  }
}

// ALL ADMIN FUNCTIONS

function Checkadmin() {
  const uid = localStorage.getItem("uid");
  const starCountRef = ref(db, "users/" + uid);
  let response;
  return new Promise((resolve) => {
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data.admin === true) {
        resolve((response = true));
      } else {
        resolve((response = false));
      }
    });
  });
}

function CheckAllData() {
  const dbRef = ref(db, "users");
  let response = [];

  return new Promise((resolve, reject) => {
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((userSnapshot) => {
        if (userSnapshot.val().mou) {
          response.push([userSnapshot.val(), userSnapshot.key]);
        }
      });

      if (response) {
        resolve(response);
      }
    });
  });
}

function GetUserData(uid) {
  const starCountRef = ref(db, "users/" + uid);
  let response;
  return new Promise((resolve, reject) => {
    onValue(starCountRef, (snapshot) => {
      response = snapshot.val();

      if (response) {
        resolve(response);
      }
    });
  });
}

export {
  checkuser,
  userstatus,
  Checkmou,
  Writedata,
  Bulkdatasubmit,
  Singledatasubmit,
  storeimg,
  CheckUserData,
  //Admin
  Checkadmin,
  CheckAllData,
  GetUserData,
};
