import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uid: "",
  profile: [],
  progresspercent: 0,
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    updateUID: (state, action) => {
      state.uid = action.payload;
    },
    updateProfile: (state, action) => {
      state.profile = action.payload;
    },
    updateStatus: (state, action) => {
      state.progresspercent = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { updateUID, updateProfile, updateStatus } = accountSlice.actions;

export default accountSlice.reducer;
